<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <VuePerfectScrollbar class="scroll-area--data-list-add-new pt-4 pb-6" :settings="settings">
    <div v-if="currentMail">
     <div class="mt-6 flex items-center justify-between px-6">
       <h4>{{currentMail.subject}}</h4>
       <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
     </div>
     <vs-divider class="mb-0"></vs-divider>
       <div class="vx-row">
         <div class="vx-col w-full">
           <vx-card class="px-4">
             <div class="vx-row w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
               <div class="vx-col sm:w-4/5 w-full flex flex-wrap items-center mb-2">
                 <vs-avatar class="sender__avatar--single flex-shrink-0 mr-3 border-2 border-solid border-white" :src="require(`@/assets/images/portrait/small/${currentMail.img}`)" size="65px"></vs-avatar>
                 <div class="flex flex-col">
                   <h5 class="mb-1">{{ currentMail.sender_name }}</h5>
                   <div class="flex items-center">
                     <span class="text-sm">{{ currentMail.sender }}</span>

                     <vs-dropdown vs-custom-content vs-trigger-click class="inline-flex cursor-pointer">
                       <feather-icon icon="ChevronDownIcon" class="w-3 h-3" />

                       <vs-dropdown-menu style="z-index: 40001">
                         <div class="p-2">
                           <p class="text-sm mb-1">From: <span class="font-semibold"> {{ currentMail.sender }} </span></p>
                           <p class="text-sm mb-1">To: <span class="font-semibold"> {{ currentMail.to }} </span></p>
                           <p class="text-sm mb-1">Date: <span class="font-semibold"> {{ currentMail.time | time }} {{ currentMail.time | date(true) }} </span></p>
                           <p class="text-sm mb-1" v-if="currentMail.cc.length">cc: <span class="font-semibold"> {{ currentMail.cc }} </span></p>
                         </div>
                       </vs-dropdown-menu>
                     </vs-dropdown>
                   </div>
                 </div>
               </div>
               <div class="vx-col sm:w-1/5 w-full flex sm:flex-col items-center sm:justify-end mb-2">
                 <span class="flex sm:mr-0 mr-2 self-end whitespace-no-wrap">{{ currentMail.time | time }}</span>
                 <span class="flex self-end sm:mt-2 mt-0 whitespace-no-wrap">{{ currentMail.time | date(true) }}</span>
               </div>
             </div>

             <!-- MAIL CONTENT -->
             <div class="vx-row">
               <div class="vx-col w-full">
                 <div class="mail__content break-words mt-8 mb-4" v-html="currentMail.message"></div>
               </div>
             </div>

             <!-- MAIL ATTACHMENTS -->
             <div class="vx-row" v-if="currentMail.attachments.length">
               <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid mb-6 flex">
                 <feather-icon icon="PaperclipIcon" class="mr-2" />
                 <span class="block py-4">ATTACHMENTS</span>
               </div>
               <div class="flex">
                 <div class="mail__attachment" v-for="(attachment, index) in currentMail.attachments" :key="index">
                   <vs-chip color="primary"  style="cursor: pointer"  class="px-4 py-2 mr-3"><p @click="downloadFile(attachment.storage_name)">{{ attachment.attachment_name }}</p></vs-chip>
                 </div>
               </div>

             </div>
           </vx-card>
         </div>
       </div>

   </div>
    </VuePerfectScrollbar>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      openMailId: {
        required: true,
        validator: prop => typeof prop === 'number' || prop === null
      },
    },
    data() {
      return {
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
      }
    },
    computed: {
      currentMail() {
        return this.$store.getters['email/getMail'](this.openMailId)
      },
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if(!val) {
            this.$emit('closeSidebar');
            this.initValues();
          }
        }
      }
    },
    methods: {
      downloadFile(file){
        this.axios.get('/download/'+file, {
          responseType: 'blob',
        }).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', file);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      },
    },
    components: {
      VuePerfectScrollbar,
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    /deep/ .vs-sidebar--background {
      display: none;
    }

    /deep/ .vs-sidebar {
      z-index: 2010;
      margin-top: 80px;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    height: calc(100% - 4.3rem);
  }
</style>

