<template>
  <vs-row>
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <img :src="require('@/assets/images/logo/print_logo.png')" alt="logo" class="w-10 mr-4"><h2 class="text-center">Noble Navigation and Shipping Line</h2>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="center" class="mb-2" vs-align="center" vs-w="12">
      <h4 class="text-center">{{tripData.number}}</h4>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="center" class="mb-2" vs-align="center" vs-w="12">
      <vs-button color="primary" @click="$router.push('/trip/edit?id='+tripData.id)" class="no_print" type="filled">Edit</vs-button>
    </vs-col>
    <vs-col class="mb-2" vs-w="12">
      <hr/>
    </vs-col>

    <vs-col  vs-w="6">
      <table class="border-collapse" >
        <tr>
          <td><b class="uppercase strong black-text">Ship</b></td>
          <td><strong>{{tripData.ship.name}}</strong></td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Trip Number</b></td>
          <td>{{tripData.number}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Cargo</b></td>
          <td>{{tripData.cargo?tripData.cargo:'NA'}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Cargo Quantity</b></td>
          <td>{{tripData.cargo_quantity?tripData.cargo_quantity:'NA'}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Total Fuel</b></td>
          <td>{{tripData.total_fuel?tripData.total_fuel:'NA'}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">From</b></td>
          <td>{{tripData.from?tripData.from:'NA'}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">To</b></td>
          <td>{{tripData.to?tripData.to:'NA'}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Status</b></td>
          <td>{{tripData.status}}</td>
        </tr>
      </table>
    </vs-col>
    <vs-col  vs-w="6">
      <table>
        <tr>
          <td><b class="uppercase strong black-text">Loading Date</b></td>
          <td>{{tripData.start_date | formatDate}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Sailing Date</b></td>
          <td>{{tripData.sailing_start | formatDate}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Sailing End Date</b></td>
          <td>{{tripData.sailing_end | formatLongDate}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Trip End Date</b></td>
          <td>{{tripData.end_date | formatDate}}</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Trip Duration</b></td>
          <td>{{tripData.duration}} days</td>
        </tr>
        <tr>
          <td><b class="uppercase strong black-text">Type</b></td>
          <td>{{tripData.type}}</td>
        </tr>
        <!--<tr v-if="tripData.type == 'Double'">-->
        <tr>
          <td><b class="uppercase strong black-text">Double Trip</b></td>
          <td>{{tripData.d_day | formatDate}}</td>
        </tr>
      </table>
    </vs-col>
    <vs-col  vs-w="6">
      <h3 style="border-bottom: 1px solid black" class="mt-2">Expenses <vs-button class="float-right no_print" color="warning" size="small" @click="addExpense(tripData.id)"  style="padding: 5px">Add expense</vs-button></h3>
      <table v-if="tripData.expense !=0" class="border-collapse">
          <thead>
          <tr class="p-2 border border-solid bg-primary text-white d-theme-border-grey-light">
            <th>Date</th>
            <th>Detail</th>
            <th>Amount</th>
            <th class="no_print">Action</th>
          </tr>
          </thead>
        <tbody>
        <tr v-for="ex in tripData.expenses" :key="ex.id" class="p-2 border border-solid d-theme-border-grey-light">
          <td>{{ex.created_at | formatDate}}</td>
          <td>{{ex.detail}}</td>
          <td>{{ex.amount | currency}}</td>
          <td class="no_print"><vs-button size="small" @click="edit(ex.id)" style="padding: 5px"><vs-icon icon="edit"></vs-icon></vs-button> <vs-button size="small" @click="confirmDelete(ex.id)" style="padding: 5px"><vs-icon icon="delete"></vs-icon></vs-button> <vs-button size="small" @click="$router.push('/attachments/'+ex.id)" style="padding: 5px"><vs-icon icon="link"></vs-icon></vs-button></td>
        </tr>
        <tr class="p-2 border border-solid d-theme-border-grey-light">
          <td colspan="3" class="text-right">Total = {{tripData.expense | currency}}</td>
        </tr>
        </tbody>
      </table>
      <p v-else class="text-center">No Expense</p>
    </vs-col>
    <vs-col  vs-w="6">
      <h3 style="border-bottom: 1px solid black" class="mt-2">Incomes <vs-button class="float-right no_print" color="warning" size="small" @click="addIncome(tripData.id)"  style="padding: 5px">Add Income</vs-button></h3>
      <table v-if="tripData.income != 0">
        <thead>
        <tr class="p-2 border border-solid bg-primary text-white d-theme-border-grey-light">
          <th>Date</th>
          <th>Detail</th>
          <th>Amount</th>
          <th class="no_print">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="ex in tripData.incomes" :key="ex.id" class="p-2 border border-solid d-theme-border-grey-light">
          <td>{{ex.created_at | formatDate}}</td>
          <td>{{ex.detail}}</td>
          <td>{{ex.amount | currency}}</td>
          <td class="no_print"><vs-button size="small" @click="edit(ex.id)" style="padding: 5px"><vs-icon icon="edit"></vs-icon></vs-button> <vs-button size="small" @click="confirmDelete(ex.id)" style="padding: 5px"><vs-icon icon="delete"></vs-icon></vs-button> <vs-button size="small" @click="$router.push('/attachments/'+ex.id)" style="padding: 5px"><vs-icon icon="link"></vs-icon></vs-button></td>
        </tr>
        <tr class="p-2 border border-solid  d-theme-border-grey-light">
          <td colspan="3" class="text-right">Total = {{tripData.income | currency}}</td>
        </tr>
        </tbody>
      </table>
      <p v-else class="text-center">No Income</p>
    </vs-col>
    <vs-col vs-w="12">
      <hr>
    </vs-col>
    <vs-col vs-offset="6"  vs-w="6">

      <table >
        <tbody>
          <tr  class="p-2 border border-solid d-theme-border-grey-light">
            <td>Total Income</td>
            <td>{{tripData.income | currency}}</td>
          </tr>
          <tr  class="p-2 border border-solid d-theme-border-grey-light">
            <td>Total Expense</td>
            <td>{{tripData.expense | currency}}</td>
          </tr>
          <tr  class="p-2 border border-solid d-theme-border-grey-light">
            <td>Profit</td>
            <td>{{tripData.profit | currency}}</td>
          </tr>
        </tbody>
      </table>
    </vs-col>
    <div v-if="action == 'Edit'">
      <vs-popup :title="transactionForm.action" style="z-index: 500000" :active.sync="popupActive2">
        <vx-input-group class="mb-base">
          <datepicker v-if="$auth.check(['date'])" class="text-center" v-model="transactionForm.date"> </datepicker>
        </vx-input-group>
        <vx-input-group class="mb-base">
          <vs-input  label="Details" v-validate="'required'"  name="detail" v-model="transactionForm.detail" placeholder="Detail" />
          <span class="text-danger text-sm" v-show="errors.has('detail')">{{ errors.first('detail') }}</span>
        </vx-input-group>
        <vx-input-group class="mb-base">
          <vs-input v-validate="'required|decimal'"  name="amount" label="Amount" v-model="transactionForm.amount"  placeholder="Amount" />
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
        </vx-input-group>
        <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Save</vs-button>
      </vs-popup>
    </div>
      <div v-show="action == 'Add'">
        <vs-popup :title="newTrFrom.action" style="z-index: 500000" :active.sync="popupShow">
          <vx-input-group class="mb-base">
            <datepicker v-if="$auth.check(['date'])" class="text-center" v-model="newTrFrom.date"> </datepicker>
          </vx-input-group>
          <vx-input-group class="mb-base">
            <vs-input  label="Details" v-validate="'required'"  name="Details" v-model="newTrFrom.detail" placeholder="Detail" />
            <span class="text-danger text-sm" v-show="errors.has('Details')">{{ errors.first('Details') }}</span>
          </vx-input-group>
          <vx-input-group class="mb-base">
            <vs-input v-validate="'required|decimal'"  name="Amounts" label="Amount" v-model="newTrFrom.amount"  placeholder="Amount" />
            <span class="text-danger text-sm" v-show="errors.has('Amounts')">{{ errors.first('Amounts') }}</span>
          </vx-input-group>
          <div class="flex" v-if="newTrFrom.attachments">
            <div class="mail__attachment" :key="index" v-for="(attachment, index) in newTrFrom.attachments">
              <vs-chip color="primary" @click="removeAttachment(index)"  class="px-4 py-2" closable><p>{{ attachment.attachment_name }}</p></vs-chip>
            </div>
          </div>
          <vs-upload :key="uploadKey" @on-success="uploadSuccess" @on-error="uploadFailed" fileName="attachment[]" :action="baseUrl+'/api/upload'" :automatic="true" :headers="{Authorization:'Bearer '+$auth.token()}"    multiple text="Attachments" :show-upload-button="true" />
          <vs-button type="filled" @click.prevent="submitAddForm" class="mt-5 block">Save</vs-button>
        </vs-popup>
      </div>

    <div class="demo-alignment">
      <vs-popup class="holamundo" title="Are you sure?" :active.sync="deleteConfirmation">
        <p>This action can not undone! If this is mistake click cancel</p>
        <div class="flex justify-between flex-wrap">
          <vs-button class="mt-4 shadow-lg" type="gradient" @click="deleteTr(deleteId)" color="danger">Delete</vs-button>
          <vs-button class="mt-4 shadow-lg" type="gradient" @click="deleteConfirmation=false" color="success">Cancel</vs-button>
        </div>

      </vs-popup>
    </div>
  </vs-row>
</template>
<script>
  import Datepicker from 'vuejs-datepicker';
  export default {
    props:['tripData'],
    data(){
      return {
        deleteConfirmation:false,
        deleteId:'',
        transactionForm:{
          id:'',
          action:'',
          type:'',
          trip_id:'',
          date: new Date(),
          detail:'',
          amount:'',
        },
        newTrFrom:{
          action:'',
          trip_id:'',
          type:'',
          date: new Date(),
          detail:'',
          amount:'',
          attachments:[],
        },
        popupActive2: false,
        popupShow:false,
        action:'',
        baseUrl:process.env.VUE_APP_BASE_URL,
        uploadKey:0,
      }
    },
    methods:{
      submitForm() {
        this.$validator.validate('detail').then(result => {
          if(result) {
            this.$validator.validate('amount').then(result => {
              if(result) {
                this.popupActive2 = false;
                this.transactionForm.date = this.$options.filters.dateToString(this.transactionForm.date);
                this.axios.post('transaction/'+this.transactionForm.id,this.transactionForm)
                  .then(res => {
                    if (res.data.notify){
                      this.$vs.notify({
                        title:res.data.notify.title,
                        text:res.data.notify.message,
                        color:res.data.notify.type
                      })
                    }
                    if (res.data.status == 'success') {
                      this.transactionForm = {
                        id:'',
                        date: '',
                        detail:'',
                        amount:'',
                      }
                    }
                    this.$validator.reset();
                    this.update();
                  })
              }
            })
          }
        })
      },
      uploadSuccess(event){
        this.newTrFrom.attachments = this.newTrFrom.attachments.concat(JSON.parse(event.currentTarget.response));
        this.uploadKey+=1;
      },
      removeAttachment(i){
        this.axios.delete('/delete/'+this.transactionForm.attachments[i].storage_name).then(()=>{
          this.transactionForm.attachments.splice(i,1);
        });
      },
      uploadFailed(event){
        console.log(event);
      },
      submitAddForm() {
        this.$validator.validate('Details').then(result => {
          if (result){
            this.$validator.validate('Amounts').then(result => {
              if(result) {
                this.popupShow = false;
                this.newTrFrom.date = this.$options.filters.dateToString(this.newTrFrom.date);
                this.axios.post('transaction/add',this.newTrFrom)
                  .then(res => {
                    if (res.data.notify){
                      this.$vs.notify({
                        title:res.data.notify.title,
                        text:res.data.notify.message,
                        color:res.data.notify.type
                      })
                    }
                    if (res.data.status == 'success') {
                      this.newTrFrom.action = '';
                      this.newTrFrom.trip_id = '';
                      this.newTrFrom.type = '';
                      this.newTrFrom.date = new Date();
                      this.newTrFrom.detail = '';
                      this.newTrFrom.amount = '';
                      this.newTrFrom.attachments = [];
                    }
                    this.$validator.reset();
                    this.uploadKey+=1;
                    this.update();
                  })
              }
            })
          }
        })
      },
      edit(id){
        this.action = 'Edit';
        this.axios.get('transaction/'+id)
          .then(res => {
            this.transactionForm.id = id;
            this.transactionForm.date = new Date(res.data.created_at);
            this.transactionForm.detail = res.data.detail;
            this.transactionForm.amount = res.data.amount;
            this.transactionForm.type = res.data.type;
            this.popupActive2 = true;
          });
      },
      confirmDelete(id){
        this.deleteConfirmation = true;
        this.deleteId = id;
      },
      deleteTr(id){
        this.deleteConfirmation = false;
        if (this.$auth.check(['income_delete', 'expense_delete'])){
          this.axios.delete('transaction/'+id)
            .then(res => {
              if (res.data.notify){
                this.$vs.notify({
                  title:res.data.notify.title,
                  text:res.data.notify.message,
                  color:res.data.notify.type
                })
              }
              this.update();
            })
        } else{
          this.$vs.notify({
            title:'Unauthorised Access !',
            text:'You are not authorized for some information.',
            color:'danger'})
        }

      },
      addExpense(tripId){
        this.action = 'Add';
        this.newTrFrom.trip_id = tripId;
        this.newTrFrom.action = 'Add Expense';
        this.newTrFrom.type = 'expense';
        this.popupShow = true;
      },
      addIncome(tripId){
        this.action = 'Add';
        this.newTrFrom.trip_id = tripId;
        this.newTrFrom.action = 'Add Income';
        this.newTrFrom.type = 'income';
        this.popupShow = true;
      },
      update(){
        this.axios.get('trip/'+this.tripData.id)
          .then(res => {
            this.tripData = res.data;
          })
      }
    },
    components: {
      Datepicker
    },
  }
</script>
<style scoped>


  table {
  border-collapse: collapse;
  width: 100%;
    margin: 10px 0px 10px 0px;
  }

  th, td {
  /*text-align: center;*/
    padding: 2px;
  }
  @media print {
    h2,h3,h4 {
      color: black;
    }
  }
</style>
