/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
  },
  {
    url: "/ship/hm1",
    name: "Hasan Hamim 1",
    slug: "hasan-hamim-1",
    icon: "DiscIcon",
  },
  {
    url: "/ship/hm3",
    name: "Hasan Hamim-3",
    slug: "hasan-hamim-3",
    icon: "DiscIcon",
  },
  {
    url: "/trips",
    name: "Trips",
    slug: "trips",
    icon: "ActivityIcon",
  },
  {
    url: "/income",
    name: "Income",
    slug: "income",
    icon: "DollarSignIcon",
  },
  {
    url: "/expense",
    name: "Expense",
    slug: "expense",
    icon: "DollarSignIcon",
  },
  {
    url: "/gexpense",
    name: "General Expense",
    slug: "gexpense",
    icon: "DollarSignIcon",
  },
  {
    url: "/users",
    name: "Users",
    slug: "users",
    icon: "UsersIcon",
  },
  {
    url: "/roles",
    name: "Roles",
    slug: "roles",
    icon: "AlignJustifyIcon",
  },
  {
    url: "/invoices",
    name: "Invoices",
    slug: "invoices",
    icon: "CreditCardIcon"
  },
  {
    url: "/payroll",
    name: "Payroll",
    slug: "payroll",
    icon: "DollarSignIcon"
  }
]
