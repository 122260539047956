<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="layout--main" :class="[navbarClasses, footerClasses, {'app-page': isAppPage}]">

        <vx-sidebar :sidebarItems="sidebarItems" :logo="require('@/assets/images/logo/logo.png')" title="NNSL" parent=".layout--main" />

        <div id="content-area" :style="{ backgroundImage: 'url(' + bg + ')', marginRight:isSidebarActive?'400px':0 }" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
            <email-bar :isSidebarActive="isSidebarActive" @closeSidebar="isSidebarActive = false" :openMailId="openMailId"></email-bar>
            <div id="content-overlay"></div>

            <div class="content-wrapper">

                <the-navbar :navbarColor="navbarColor" :class="[{'text-white': isNavbarDark && !isThemeDark}, {'text-base': !isNavbarDark && isThemeDark}]" />

                <div class="router-view">
                    <div class="router-content" :class="{'mt-0': navbarType == 'hidden'}">
                        <transition :name="routerTransition">
                        <div class="router-header flex flex-wrap items-center mb-6" v-if="$route.meta.breadcrumb || $route.meta.pageTitle">
                            <div class="content-area__heading" :class="{'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light' : $route.meta.breadcrumb}">
                                <h2 class="mb-1">{{ routeTitle }}</h2>
                            </div>

                            <!-- BREADCRUMB -->
                            <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" />
                          <vs-alert :active="!onLine" color="danger" style="min-height: 60px; text-align: center">
                            You are offline. Some feature may not work!
                          </vs-alert>

                            <!--&lt;!&ndash; DROPDOWN &ndash;&gt;-->
                            <!--<vs-dropdown class="ml-auto md:block hidden cursor-pointer" vs-trigger-click>-->
                                <!--<vs-button radius icon="icon-settings" icon-pack="feather"></vs-button>-->

                                <!--<vs-dropdown-menu class="w-32">-->

                                    <!--<vs-dropdown-item>-->
                                      <!--<div @click="$router.push('/pages/profile')" class="flex items-center">-->
                                        <!--<feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />-->
                                        <!--<span>Profile</span>-->
                                      <!--</div>-->
                                    <!--</vs-dropdown-item>-->

                                    <!--<vs-dropdown-item>-->
                                      <!--<div @click="$router.push('/apps/todo')" class="flex items-center">-->
                                        <!--<feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />-->
                                        <!--<span>Tasks</span>-->
                                      <!--</div>-->
                                    <!--</vs-dropdown-item>-->

                                    <!--<vs-dropdown-item>-->
                                      <!--<div @click="$router.push('/apps/email')" class="flex items-center">-->
                                        <!--<feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />-->
                                        <!--<span>Inbox</span>-->
                                      <!--</div>-->
                                    <!--</vs-dropdown-item>-->
                                <!--</vs-dropdown-menu>-->
                            <!--</vs-dropdown>-->
                        </div>
                        </transition>
                        <div class="content-area__content">
                            <back-to-top bottom="5%" visibleoffset="500" v-if="!hideScrollToTop">
                                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg" />
                            </back-to-top>
                            <transition :name="routerTransition" mode="out-in">
                                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
                            </transition>
                        </div>
                    </div>
                </div>

            </div>

            <the-footer></the-footer>
        </div>
    </div>
</template>

<script>
import VxSidebar from '@/layouts/components/vx-sidebar/VxSidebar.vue';
import TheNavbar from '../components/TheNavbar.vue';
import TheFooter from '../components/TheFooter.vue';
import themeConfig from '@/../themeConfig.js';
import sidebarItems from "@/layouts/components/vx-sidebar/sidebarItems.js";
import BackToTop from 'vue-backtotop'
import EmailBar from '@/views/components/EmailBar'

export default {
    data() {
        return {
            onLine: navigator.onLine,
            openMailId:null,
            isSidebarActive:false,
            navbarType: themeConfig.navbarType || 'floating',
            navbarColor: themeConfig.navbarColor || 'rgb(115, 103, 240)',
            footerType: themeConfig.footerType || 'static',
            routerTransition: themeConfig.routerTransition || 'none',
            isNavbarDark: false,
            routeTitle: this.$route.meta.pageTitle,
            sidebarItems: sidebarItems,
            disableCustomizer: themeConfig.disableCustomizer,
            windowWidth: window.innerWidth, //width of windows
            hideScrollToTop: themeConfig.hideScrollToTop,
            disableThemeTour: themeConfig.disableThemeTour
        }
    },
    watch: {
        '$route'() {
            this.routeTitle = this.$route.meta.pageTitle;
        },
        isThemeDark(val) {
            if(this.navbarColor == "#fff" && val) {
                this.updateNavbarColor("#10163a")
            }else {
                this.updateNavbarColor("rgb(115, 103, 240)")
            }
        },
    },
    computed: {
        bg(){
        if (this.$store.state.theme == 'semi-dark'){
          return require('@/assets/images/pages/weather.png');
        } else{
        }
        },
        isAppPage() {
            if(this.$route.path.includes('/apps/')) return true
            else return false
        },
        isThemeDark() { return this.$store.state.theme == 'dark' },
        sidebarWidth() {
            return this.$store.state.sidebarWidth;
        },
        bodyOverlay() {
            return this.$store.state.bodyOverlay;
        },
        contentAreaClass() {
            if(this.sidebarWidth == "default") return "content-area-default"
            else if(this.sidebarWidth == "reduced") return "content-area-reduced"
            else if(this.sidebarWidth) return "content-area-full"
        },
        navbarClasses() {
            return {
                'navbar-hidden': this.navbarType == 'hidden',
                'navbar-sticky': this.navbarType == 'sticky',
                'navbar-static': this.navbarType == 'static',
                'navbar-floating': this.navbarType == 'floating',
            }
        },
        footerClasses() {
            return {
                'footer-hidden': this.footerType == 'hidden',
                'footer-sticky': this.footerType == 'sticky',
                'footer-static': this.footerType == 'static',
            }
        },
    },
    methods: {
      updateOnlineStatus(e) {
        const {
          type
        } = e;
        this.onLine = type === 'online';
      },
        changeRouteTitle(title) {
            this.routeTitle = title;
        },
        updateNavbarColor(val) {
            this.navbarColor = val;
            if(val == "#fff") this.isNavbarDark = false
            else this.isNavbarDark = true
        },
        handleWindowResize(event) {
            this.windowWidth = event.currentTarget.innerWidth;
            this.setSidebarWidth();
        },
        setSidebarWidth() {
            if (this.windowWidth < 1200) {
                this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)
                this.$store.dispatch('updateSidebarWidth', 'no-sidebar')
                this.disableThemeTour = true;
            }
            else if(this.windowWidth < 1200) {
                this.$store.dispatch('updateSidebarWidth', 'reduced')
            }
            else {
                this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true)
            }
        },
        toggleHideScrollToTop(val) {
            this.hideScrollToTop = val;
        }
    },
    components: {
        VxSidebar,
        TheNavbar,
        TheFooter,
        BackToTop,
        EmailBar
    },
    created() {
      // this.axios.get('/shipsMenu')
      //   .then(res => {
      //       if (res.data){
      //         let i;
      //         for (i = 0; i < res.data.length; i++) {
      //           sidebarItems.splice( i+1, 0, res.data[i]);
      //         }
      //       }
      //     });
        this.setSidebarWidth();
        if(this.navbarColor == "#fff" && this.isThemeDark) {
            this.updateNavbarColor("#10163a")
        }else {
            this.updateNavbarColor(this.navbarColor)
        }
    },
    mounted(){
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
      var app = this;
      this.$root.$on('populate_sidebar', function(id){
        console.log(id);
        app.openMailId = id;
        app.isSidebarActive = true;
      })
    }
}
</script>
